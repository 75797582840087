import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ROUTES } from 'common/navigation/routes';
import { LOGOUT_DELAY, SIDEBAR_MAX_WIDTH } from 'common/constants';
import { useOverridableFeatureFlag } from 'services/configCat';
import { ReactComponent as LogoutIcon } from 'assets/svg/logout-icon.svg';
import { IconButton } from 'ui/atoms/iconButton';
import { useGetSidebarFooterButtons } from 'ui/sidebar/useGetSidebarFooterButtons';
import { useSidebarStore } from 'ui/sidebar/sidebarStore';
import { SidebarFooterButtonId } from 'ui/sidebar/enums';
import { SupportContactLink } from './supportContactLink';

interface SidebarHeaderProps {
  vertical: boolean;
}

export const SidebarFooter = ({ vertical }: SidebarHeaderProps) => {
  const enableGptChat = useOverridableFeatureFlag('enableGptChat', false, 'REACT_APP_ENABLE_GPT_CHAT');

  const enableNotifications = useOverridableFeatureFlag('enableNotifications', false, 'REACT_APP_ENABLE_NOTIFICATIONS');

  const navigate = useNavigate();

  const sidebarPanelType = useSidebarStore((state) => state.sidebarPanelType);
  const closeSidebarPanel = useSidebarStore.use.closeSidebarPanel();

  const handleLogout = () => {
    setTimeout(() => {
      closeSidebarPanel();
      navigate(ROUTES.logout);
    }, LOGOUT_DELAY);
  };

  const { sidebarFooterButtons } = useGetSidebarFooterButtons();

  const footerPosition = vertical ? -90 : 0;

  return (
    <SidebarFooterWrapper>
      <SidebarFooterContent data-testid="sidebar-footer" vertical={vertical}>
        {sidebarFooterButtons
          .filter(({ id }) => {
            switch (true) {
              case !enableGptChat && id === SidebarFooterButtonId.CHAT_GPT:
                return false;
              case !enableNotifications && id === SidebarFooterButtonId.NOTIFICATIONS:
                return false;
              default:
                return true;
            }
          })
          .map((button) => {
            const Icon = button.icon;

            return (
              <Icon
                key={button.id}
                variant={button.iconVariant}
                $iconSize={button.iconSize}
                active={sidebarPanelType === button.sidebarPanelType}
                onClick={button.action}
                rotation={footerPosition}
              />
            );
          })}
        <SupportContactLink rotation={footerPosition} />
        <IconButton
          variant="SECONDARY"
          icon={<LogoutIcon />}
          rotation={footerPosition}
          description="logout"
          onClick={handleLogout}
        />
      </SidebarFooterContent>
    </SidebarFooterWrapper>
  );
};

const SidebarFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: inherit;
  width: 100%;
`;

const SidebarFooterContent = styled.div<{ vertical: boolean }>`
  margin-top: auto;
  width: ${SIDEBAR_MAX_WIDTH}px;
  display: flex;
  justify-content: end;
  gap: 12px;
  padding: 14px 10px;
  z-index: 3;
  transition: all ${({ theme }) => theme.transitionFunction.main};

  ${({ vertical }) =>
    vertical &&
    css`
      transform: translateX(-125px) translateY(-125px) rotate(90deg);
    `}
`;
