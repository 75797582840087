import { ROUTES } from 'common/navigation/routes';
import { Container } from 'ui';
import { Link } from 'ui/atoms/link';
import { LoadingDots } from 'ui/atoms/loadingDots';
import { useSidebarStore } from 'ui/sidebar/sidebarStore';
import { useGetActiveSubscription } from 'views/subscriptions/useGetActiveSubscription';
import { useOverridableFeatureFlag } from 'services/configCat';

interface SubscriptionsLinkProps {
  sidebar?: boolean;
}

export const SubscriptionsLink = ({ sidebar }: SubscriptionsLinkProps) => {
  const enableSubscriptionManagement = useOverridableFeatureFlag(
    'enableSubscriptionManagement',
    false,
    'REACT_APP_ENABLE_SUBSCRIPTION_MANAGEMENT',
  );

  const { activeSubscriptionData, isActiveSubscriptionLoading } = useGetActiveSubscription();
  const closeSidebarPanel = useSidebarStore.use.closeSidebarPanel();

  return (
    <>
      {enableSubscriptionManagement && (
        <div>
          {isActiveSubscriptionLoading ? (
            !sidebar && (
              <Container mt={10}>
                <LoadingDots />
              </Container>
            )
          ) : activeSubscriptionData ? (
            <Link
              variant={sidebar ? 'SECONDARY_SMALL' : 'SECONDARY'}
              href={`/#${ROUTES.subscriptionsManage}`}
              onClick={sidebar ? closeSidebarPanel : undefined}
              sameSite
            >
              manage subscription
            </Link>
          ) : (
            <Link
              variant={sidebar ? 'PRIMARY_SMALL' : 'PRIMARY'}
              href={`/#${ROUTES.subscriptionsStart}`}
              onClick={sidebar ? closeSidebarPanel : undefined}
              sameSite
            >
              start subscription
            </Link>
          )}
        </div>
      )}
    </>
  );
};
