import styled from 'styled-components';

import { radiansToDegrees, roundNumber } from 'common/utils';
import { Container } from 'ui';
import { ColorScaleBar } from 'ui/colorScale/ColorScaleBar';
import { type PointInfo, type SingleBandPointInfo } from 'services/titiler/types';
import { WithCogBandRange } from 'services/titiler/WithCogBandRange';
import { type ExplorationLayer } from 'domain/exploration/types';
import { INDICES_SLIDER_CONFIG, MINERALIZATION_SLIDER_CONFIG } from 'domain/exploration/configs';
import { SectionLayout } from './SectionLayout';
import { PixelStatisticsTableRow } from './PixelStatisticsTableRow';
import { StatisticsDot, StatisticsValue } from './styled';

interface Props {
  pixelStatistics: PointInfo<ExplorationLayer, SingleBandPointInfo>[];
}

export const PixelStatisticsSection = ({ pixelStatistics }: Props) => {
  const samData = pixelStatistics
    .filter(({ group }) => group === 'SAM')
    .map(({ uid, label, colormap, threshold, info: { values } }) => {
      const value = roundNumber(values[0], MINERALIZATION_SLIDER_CONFIG.sam.precision);
      const isBelowThreshold = typeof threshold === 'number' ? value < threshold : false;
      const valueInDegrees = radiansToDegrees(Math.acos(value)).toFixed(2);

      return {
        uid,
        label,
        value: valueInDegrees,
        colormap,
        isBelowThreshold,
        secondColor: null,
      };
    });

  const indicesData = pixelStatistics
    .filter(({ group }) => group === 'GSI')
    .map(({ uid, label, url, colormap, threshold, info: { values } }) => {
      const value = roundNumber(values[0], INDICES_SLIDER_CONFIG.precision);
      const isBeyondThreshold = Array.isArray(threshold) ? value < threshold[0] || value > threshold[1] : false;

      return {
        uid,
        label,
        value: roundNumber(values[0], INDICES_SLIDER_CONFIG.precision),
        colormap,
        url,
        isBeyondThreshold,
      };
    });

  return (
    <section>
      <Container pt={20} variant="VERTICAL_STRETCH">
        <SectionLayout
          title="pixel statistics"
          description="Select layers from the control panel and click a map pixel within the Area of Interest to see pixel statistics"
        >
          {pixelStatistics.length ? (
            <>
              {!!samData.length && (
                <ClassifierStatsList>
                  {samData.map(({ uid, label, value, colormap, isBelowThreshold, secondColor }) => (
                    <StyledListItem key={uid}>
                      <StatisticsDot secondColor={secondColor || undefined}>
                        <ColorScaleBar colormap={colormap} />
                      </StatisticsDot>
                      <StatisticsValue grayed={isBelowThreshold}>
                        {label}: {value}°
                      </StatisticsValue>
                    </StyledListItem>
                  ))}
                </ClassifierStatsList>
              )}
              {!!indicesData.length && (
                <StyledTable>
                  <tbody>
                    <tr>
                      <th></th>
                      <th>Value</th>
                      <th>Min. AOI value</th>
                      <th>Max. AOI value</th>
                    </tr>
                    {indicesData.map(({ uid, label, value, colormap, url, isBeyondThreshold }) => (
                      <WithCogBandRange
                        key={uid}
                        Component={PixelStatisticsTableRow}
                        props={{ label, value, colormap, grayed: isBeyondThreshold }}
                        url={url}
                        precision={INDICES_SLIDER_CONFIG.precision}
                      />
                    ))}
                  </tbody>
                </StyledTable>
              )}
            </>
          ) : (
            'No data'
          )}
        </SectionLayout>
      </Container>
    </section>
  );
};

const ClassifierStatsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 15px 0 35px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  font-family: 'Barlow', sans-serif;
  font-size: 12px;
  font-weight: 400;
`;

const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledTable = styled.table`
  font-size: 12px;
  line-height: 16px;
  border-collapse: collapse;

  > tbody {
    > tr {
      :first-child {
        border-bottom: 1px solid ${({ theme }) => theme.color.whiteTransparent10};
      }

      th {
        font-weight: 500;
      }

      th,
      td {
        padding: 5px 10px;
        text-align: center;
      }

      th:first-child,
      td:first-child {
        padding-left: 0;
      }
    }
  }
`;
