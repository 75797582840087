import styled from 'styled-components';

import { getPercentageValue, radiansToDegrees } from 'common/utils';
import { type TitilerStats } from 'services/titiler/types';
import { YAxisValues } from './YAxisValues';
import { Lines } from './Lines';
import { Bars } from './Bars';
import { ThresholdMarker } from './ThresholdMarker';
import { XAxisValues } from './XAxisValues';
import { mapXYValuesToSegments } from './helpers';

interface HistogramGraphProps {
  stats: TitilerStats;
  range: number[];
  threshold?: number;
  graphAllowance?: number;
}

export const HistogramGraph = ({ stats, range, threshold, graphAllowance }: HistogramGraphProps) => {
  const xValuesInCosine = stats?.b1?.histogram[1];

  const yValues = stats?.b1?.histogram[0];

  const segments = mapXYValuesToSegments(xValuesInCosine, yValues);

  return (
    <Wrapper>
      <YAxisLabel>
        <p>pixel count</p>
      </YAxisLabel>
      <YAxisValues values={yValues} allowance={graphAllowance} />
      <InnerWrapper>
        <BarLinesContainer>
          <Lines />
          <Bars segments={segments} range={range} allowance={graphAllowance} />
          {threshold && <ThresholdMarker value={getPercentageValue(radiansToDegrees(Math.acos(threshold)), range)} />}
        </BarLinesContainer>
        <XAxis />
        <XAxisValues range={range} />
        <XAxisLabel>spectral angle value (degrees)</XAxisLabel>
      </InnerWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  display: grid;
  grid-template-columns: 5% 5% 90%;
  margin: 0 auto;
  background: ${({ theme }) => theme.color.mineShaftABitLighter};
  z-index: -25;
  padding: 8px 12px;
  font-family: 'Barlow', sans-serif;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
`;

const YAxisLabel = styled.div`
  color: ${({ theme }) => theme.color.white};
  display: grid;
  place-items: center;
  height: 85%;

  p {
    transform: rotate(-90deg) translateY(-125%);
    transform-origin: center;
    white-space: nowrap;
    margin: 0;
  }
`;

const InnerWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 85% 0 10% 5%;
`;

const BarLinesContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-end;
`;

const XAxis = styled.div`
  position: relative;
  left: 0;
  right: 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.silver};
  bottom: 0;
  z-index: -1;
`;

const XAxisLabel = styled.div`
  color: ${({ theme }) => theme.color.white};
  text-align: center;
`;
