import styled from 'styled-components';

export const StatisticsDot = styled.div<{ secondColor?: string }>`
  > div {
    min-width: auto;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
`;

export const StatisticsValue = styled.span<{ grayed?: boolean }>`
  opacity: ${({ grayed }) => grayed && 0.7};
`;
