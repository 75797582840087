import { type ColormapDictionary } from 'common/types';

export const TURBO_COLORMAP: ColormapDictionary = {
  0: '#30123b',
  0.1: '#455bcd',
  0.15: '#477cf3',
  0.2: '#3e9cfe',
  0.25: '#28bceb',
  0.3: '#18d7cb',
  0.35: '#21ebab',
  0.4: '#48f882',
  0.45: '#7afe58',
  0.5: '#a4fc3c',
  0.55: '#c5f034',
  0.6: '#e2dc38',
  0.65: '#f6c23a',
  0.7: '#fea331',
  0.75: '#fb7e21',
  0.8: '#ef5911',
  0.85: '#dc3c07',
  0.9: '#c22403',
  0.95: '#a01201',
  1: '#7a0403',
};

export const SPECTRAL_COLORMAP: ColormapDictionary = {
  0: '#5e4fa2',
  0.1: '#3288bd',
  0.2: '#66c2a5',
  0.3: '#abdda4',
  0.4: '#e6f598',
  0.5: '#ffffbf',
  0.6: '#fee08b',
  0.7: '#fdae61',
  0.8: '#f46d43',
  0.9: '#d53e4f',
  1: '#9e0142',
};

export const MTMF_GRADIENT_COLORMAP = {
  0: '#FFCD00',
  0.25: '#FFA401',
  0.5: '#FF7201',
  0.75: '#FF3939',
};

export const SPECTRAL_INDICATOR_GRADIENT_START_COLOR = '#ffffff';

export const PROSPECTING_OVERVIEW_SITE_HOVER_C0LOR = '#FFF';
export const PROSPECTING_OVERVIEW_SITE_TARGET_POINT_C0LOR = '#B6722F';
export const PROSPECTING_OVERVIEW_LINES_C0LOR = '#515151';
