import styled from 'styled-components';

import { useOverridableFeatureFlag } from 'services/configCat';
import { TitledSection } from 'ui/atoms/titledSection';
import { WithCustomScrollbarWrapper } from 'ui/storyUtils';
import { ResetPassword } from 'ui/userSettings/ResetPassword';
import { SubscriptionSection } from 'views/subscriptions/SubscriptionSection';

interface UserSettingsProps {
  userName: string;
  email: string | undefined;
}

export const UserSettings = ({ email, userName }: UserSettingsProps) => {
  const enableUserSettings = useOverridableFeatureFlag('enableUserSettings', false, 'REACT_APP_ENABLE_USER_SETTINGS');

  return (
    <StyledUserSettings>
      <UserSettingsContent>
        <UserInformation>
          <p>
            <span>User Name:</span> {userName}
          </p>
          <p>
            <span>Email:</span> {email}
          </p>
        </UserInformation>
        <ResetPassword />
        {enableUserSettings && (
          <>
            <TitledSection title="units in app">
              <RadioGroup>
                <RadioLabel>
                  <RadioInput defaultChecked type="radio" name="units" value="international" />
                  International System of Units
                </RadioLabel>
                <RadioLabel>
                  <RadioInput type="radio" name="units" value="imperial" />
                  Imperial System of Units
                </RadioLabel>
              </RadioGroup>
            </TitledSection>
            <TitledSection title="app color mode">
              <RadioGroup>
                <RadioLabel>
                  <RadioInput type="radio" name="colorMode" value="light" />
                  Light
                </RadioLabel>
                <RadioLabel>
                  <RadioInput defaultChecked type="radio" name="colorMode" value="dark" />
                  Dark
                </RadioLabel>
              </RadioGroup>
            </TitledSection>
          </>
        )}
        <SubscriptionSection />
      </UserSettingsContent>
    </StyledUserSettings>
  );
};

const StyledUserSettings = styled.div`
  padding: 36px 10px 36px 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const UserSettingsContent = styled(WithCustomScrollbarWrapper)`
  height: 100%;
  padding: 0 10px 20px 0;
  overflow-y: auto;
`;

const UserInformation = styled.div`
  display: grid;
  gap: 12px;

  p {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 75px 1fr;
    gap: 6px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
`;

const RadioLabel = styled.label`
  margin: 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 18px;
  cursor: pointer;
`;

const RadioInput = styled.input`
  position: relative;
  appearance: none;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.color.primary};
  cursor: pointer;

  &:checked {
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: 50%;
      margin: 2px;
      background: ${({ theme }) => theme.color.primary};
    }
  }
`;
