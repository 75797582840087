import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { createSelectors } from 'common/utils/createSelectors';
import { type PointInfo, type SingleBandPointInfo } from 'services/titiler/types';
import { type ExplorationLayer, type GradientModeStateDictionary } from 'domain/exploration/types';

interface ExplorationLayerManagerState {
  gradientModeState: GradientModeStateDictionary;
  setGradientModeState: (classifierId: number, mode: boolean) => void;
  toggleGradientMode: (classifierId: number) => void;
  mineralIndicatorRange: number[];
  setMineralIndicatorRange: (range: number[]) => void;
  isClustersVisible: boolean;
  toggleClustersVisibility: () => void;
  clustersSupplierId: number | null;
  setClustersSupplierId: (id: number) => void;
  clustersAmount: number;
  setClustersAmount: (amount: number) => void;
  isClustersSelectionMode: boolean;
  enableClustersSelectionMode: () => void;
  confirmClustersSelection: () => void;
  selectedClustersSaved: number[];
  selectedClustersInProgress: number[];
  toggleSelectedCluster: (no: number) => void;
  clearSelectedClusters: () => void;
  cancelClustersSelection: () => void;
  clearClustersData: () => void;
  areLineamentsVisible: boolean;
  toggleLineamentsVisibility: () => void;
  lineamentsOpacity: number;
  setLineamentsOpacity: (opacity: number) => void;
  isLineamentsSelectionMode: boolean;
  enableLineamentsSelectionMode: () => void;
  confirmLineamentsSelection: () => void;
  selectedLineamentsSaved: number[];
  selectedLineamentsInProgress: number[];
  toggleSelectedLineament: (no: number) => void;
  clearSelectedLineaments: () => void;
  cancelLineamentsSelection: () => void;
  isLineamentDensityVisible: boolean;
  toggleLineamentDensityVisibility: () => void;
  lineamentDensityOpacity: number;
  setLineamentDensityOpacity: (opacity: number) => void;
  samLayersOrderRecord: Record<string, number[]> | null;
  setSamLayersOrderRecord: (key: string, layersOrder: number[]) => void;
  mtmfLayersOrderRecord: Record<string, number[]> | null;
  setMtmfLayersOrderRecord: (key: string, layersOrder: number[]) => void;
  clearLineamentsData: () => void;
  explorationLayersPointInfo: PointInfo<ExplorationLayer, SingleBandPointInfo>[];
  setExplorationLayersPointInfo: (info: PointInfo<ExplorationLayer, SingleBandPointInfo>[]) => void;
}

const initialState = {
  gradientModeState: {},
  mineralIndicatorRange: [0, 1],
  isClustersVisible: false,
  clustersSupplierId: null,
  clustersAmount: 20,
  isClustersSelectionMode: false,
  selectedClustersSaved: [],
  selectedClustersInProgress: [],
  areLineamentsVisible: false,
  lineamentsOpacity: 1,
  isLineamentsSelectionMode: false,
  selectedLineamentsSaved: [],
  selectedLineamentsInProgress: [],
  lineamentDensityOpacity: 1,
  isLineamentDensityVisible: false,
  samLayersOrderRecord: null,
  mtmfLayersOrderRecord: null,
  explorationLayersPointInfo: [],
};

const useExplorationLayerManagerStoreBase = create<ExplorationLayerManagerState>()(
  devtools(
    persist(
      (set) => ({
        gradientModeState: initialState.gradientModeState,
        setGradientModeState: (classifierId: number, mode: boolean) =>
          set(
            (state) => {
              const newState = { ...state.gradientModeState };
              newState[classifierId] = mode;

              return { gradientModeState: newState };
            },
            false,
            'layerManager/setGradientModeState',
          ),
        toggleGradientMode: (classifierId: number) =>
          set(
            (state) => {
              const newState = { ...state.gradientModeState };
              newState[classifierId] = !newState[classifierId];

              return { gradientModeState: newState };
            },
            false,
            'layerManager/toggleGradientMode',
          ),
        mineralIndicatorRange: initialState.mineralIndicatorRange,
        setMineralIndicatorRange: (range: number[]) =>
          set({ mineralIndicatorRange: range }, false, 'layerManager/setMineralIndicatorRange'),
        isClustersVisible: initialState.isClustersVisible,
        toggleClustersVisibility: () =>
          set(
            (state) => ({ isClustersVisible: !state.isClustersVisible }),
            false,
            'layerManager/toggleClustersVisibility',
          ),
        clustersSupplierId: initialState.clustersSupplierId,
        setClustersSupplierId: (id: number) =>
          set({ clustersSupplierId: id }, false, 'layerManager/setClustersSupplierId'),
        clustersAmount: initialState.clustersAmount,
        setClustersAmount: (amount: number) => set({ clustersAmount: amount }, false, 'layerManager/setClustersAmount'),
        isClustersSelectionMode: initialState.isClustersSelectionMode,
        enableClustersSelectionMode: () =>
          set(
            (state) => ({ isClustersSelectionMode: true, selectedClustersInProgress: state.selectedClustersSaved }),
            false,
            'layerManager/enableClustersSelectionMode',
          ),
        confirmClustersSelection: () =>
          set(
            (state) => ({ isClustersSelectionMode: false, selectedClustersSaved: state.selectedClustersInProgress }),
            false,
            'layerManager/disableClustersSelectionMode',
          ),
        selectedClustersSaved: initialState.selectedClustersSaved,
        selectedClustersInProgress: initialState.selectedClustersInProgress,
        toggleSelectedCluster: (no: number) => {
          set(
            (state) => {
              const clustersSet = new Set(state.selectedClustersInProgress);
              clustersSet.has(no) ? clustersSet.delete(no) : clustersSet.add(no);

              return { selectedClustersInProgress: Array.from(clustersSet) };
            },
            false,
            'layerManager/toggleSelectedCluster',
          );
        },
        clearSelectedClusters: () =>
          set(
            {
              selectedClustersSaved: initialState.selectedClustersSaved,
              selectedClustersInProgress: initialState.selectedClustersSaved,
            },
            false,
            'layerManager/clearSelectedClusters',
          ),
        clearClustersData: () =>
          set(
            {
              isClustersVisible: initialState.isClustersVisible,
              clustersSupplierId: initialState.clustersSupplierId,
              clustersAmount: initialState.clustersAmount,
              isClustersSelectionMode: initialState.isClustersSelectionMode,
              selectedClustersSaved: initialState.selectedClustersSaved,
              selectedClustersInProgress: initialState.selectedClustersInProgress,
            },
            false,
            'layerManager/clearClustersData',
          ),
        cancelClustersSelection: () =>
          set(
            { selectedClustersInProgress: initialState.selectedClustersInProgress, isClustersSelectionMode: false },
            false,
            'layerManager/cancelClustersSelection',
          ),
        areLineamentsVisible: initialState.areLineamentsVisible,
        toggleLineamentsVisibility: () =>
          set(
            (state) => ({ areLineamentsVisible: !state.areLineamentsVisible }),
            false,
            'layerManager/toggleLineamentsVisibility',
          ),
        lineamentsOpacity: initialState.lineamentsOpacity,
        setLineamentsOpacity: (opacity: number) =>
          set({ lineamentsOpacity: opacity }, false, 'layerManager/setLineamentsOpacity'),
        isLineamentsSelectionMode: initialState.isLineamentsSelectionMode,
        enableLineamentsSelectionMode: () =>
          set(
            (state) => ({
              isLineamentsSelectionMode: true,
              selectedLineamentsInProgress: state.selectedLineamentsSaved,
            }),
            false,
            'layerManager/enableLineamentsSelectionMode',
          ),
        confirmLineamentsSelection: () =>
          set(
            (state) => ({
              isLineamentsSelectionMode: false,
              selectedLineamentsSaved: state.selectedLineamentsInProgress,
            }),
            false,
            'layerManager/disableLineamentsSelectionMode',
          ),
        selectedLineamentsSaved: initialState.selectedLineamentsSaved,
        selectedLineamentsInProgress: initialState.selectedLineamentsInProgress,
        toggleSelectedLineament: (no: number) => {
          set(
            (state) => {
              const lineamentsSet = new Set(state.selectedLineamentsInProgress);
              lineamentsSet.has(no) ? lineamentsSet.delete(no) : lineamentsSet.add(no);

              return { selectedLineamentsInProgress: Array.from(lineamentsSet) };
            },
            false,
            'layerManager/toggleSelectedLineament',
          );
        },
        cancelLineamentsSelection: () =>
          set(
            (state) => ({
              selectedLineamentsInProgress: state.selectedLineamentsSaved,
              isLineamentsSelectionMode: false,
            }),
            false,
            'layerManager/cancelLineamentsSelection',
          ),
        clearSelectedLineaments: () =>
          set(
            {
              selectedLineamentsSaved: initialState.selectedLineamentsSaved,
              selectedLineamentsInProgress: initialState.selectedLineamentsSaved,
            },
            false,
            'layerManager/clearSelectedLineaments',
          ),
        lineamentDensityOpacity: initialState.lineamentDensityOpacity,
        setLineamentDensityOpacity: (opacity: number) =>
          set({ lineamentDensityOpacity: opacity }, false, 'layerManager/setLineamentDensityOpacity'),
        isLineamentDensityVisible: initialState.isLineamentDensityVisible,
        toggleLineamentDensityVisibility: () =>
          set(
            (state) => ({ isLineamentDensityVisible: !state.isLineamentDensityVisible }),
            false,
            'layerManager/toggleLineamentDensityVisibility',
          ),
        clearLineamentsData: () =>
          set(
            {
              areLineamentsVisible: initialState.areLineamentsVisible,
              lineamentsOpacity: initialState.lineamentsOpacity,
              isLineamentsSelectionMode: initialState.isLineamentsSelectionMode,
              selectedLineamentsSaved: initialState.selectedLineamentsSaved,
              selectedLineamentsInProgress: initialState.selectedLineamentsInProgress,
              lineamentDensityOpacity: initialState.lineamentDensityOpacity,
              isLineamentDensityVisible: initialState.isLineamentDensityVisible,
            },
            false,
            'layerManager/clearLineamentsData',
          ),
        samLayersOrderRecord: initialState.samLayersOrderRecord,
        setSamLayersOrderRecord: (key: string, layersOrder: number[]) =>
          set(
            (state) => ({
              samLayersOrderRecord: {
                ...state.samLayersOrderRecord,
                [key]: layersOrder,
              },
            }),
            false,
            'layerManager/setSamLayersOrderRecord',
          ),
        mtmfLayersOrderRecord: initialState.mtmfLayersOrderRecord,
        setMtmfLayersOrderRecord: (key: string, layersOrder: number[]) =>
          set(
            (state) => ({
              mtmfLayersOrderRecord: {
                ...state.mtmfLayersOrderRecord,
                [key]: layersOrder,
              },
            }),
            false,
            'layerManager/setMtmfLayersOrderRecord',
          ),
        explorationLayersPointInfo: initialState.explorationLayersPointInfo,
        setExplorationLayersPointInfo: (info: PointInfo<ExplorationLayer, SingleBandPointInfo>[]) =>
          set({ explorationLayersPointInfo: info }, false, 'layerManager/setExplorationLayersPointInfo'),
      }),
      { name: 'RSOM_explorationLayerManagerStore', storage: createJSONStorage(() => localStorage) },
    ),
    { name: 'explorationLayerManagerStore' },
  ),
);

export const useExplorationLayerManagerStore = createSelectors(useExplorationLayerManagerStoreBase);
