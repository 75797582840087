import React from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider } from 'styled-components';
import { Normalize } from 'styled-normalize';
import { MathJaxContext } from 'better-react-mathjax';

import { GlobalStyle } from 'common/GlobalStyle';
import { mathJaxConfig } from 'common/configs';
import { theme } from 'common/theme';
import { queryClient } from 'services/query/queryClient';
import { enableMocking } from 'services/msw';
import App from 'App';
import { ConfigCat } from 'services/configCat';
import { AppCookieBanner } from 'components';
import { registerServiceWorker } from 'serviceWorker';
import { Fallback } from 'ui';

const container = document.getElementById('root') as HTMLElement;

const root = createRoot(container);

enableMocking().then(() =>
  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
        <ThemeProvider theme={theme}>
          <Normalize />
          <GlobalStyle />
          <ErrorBoundary FallbackComponent={Fallback}>
            <ConfigCat>
              <AppCookieBanner />
              <MathJaxContext config={mathJaxConfig}>
                <App />
              </MathJaxContext>
            </ConfigCat>
          </ErrorBoundary>
        </ThemeProvider>
      </QueryClientProvider>
    </React.StrictMode>,
  ),
);

registerServiceWorker();
