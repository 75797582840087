import styled from 'styled-components';

import { getShortenedMultiple, roundToNearest } from 'common/utils';

interface YAxisValuesProps {
  values: number[];
  allowance?: number;
}

export const YAxisValues = ({ values, allowance = 0 }: YAxisValuesProps) => {
  const max = Math.max(...values);

  const localMax = roundToNearest(max);

  const step = (localMax - 0) / 5;
  const labels = Array(6)
    .fill(null)
    .map((_, i) => 0 + i * step);

  const correction = (100 + allowance) / 100;

  return (
    <Wrapper>
      {labels.map((value) => (
        <Label key={value} offset={value / localMax} correction={correction}>
          {getShortenedMultiple(value)}
        </Label>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  height: 85%;
  width: 100%;
`;

const Label = styled.li<{ offset: number; correction: number }>`
  position: relative;
  bottom: ${({ offset, correction }) => `${(offset * 100) / correction}%`};
  line-height: 0;
`;
