import { type ComponentType } from 'react';

import { type TitilerStats } from './types';
import { titilerInfoService } from './titilerInfoService';
import { getAzureBlobStorageUrl } from './utils';

interface Props<T> {
  Component: ComponentType<T & { stats: TitilerStats }>;
  LoadingComponent: ComponentType;
  props: T;
  url: string;
  range: number[];
  bins: number;
}

export const WithCogStatistics = <T,>({ Component, LoadingComponent, props, url, range, bins }: Props<T>) => {
  const { stats, isStatsLoading } = titilerInfoService.useGetStatsGeneric(getAzureBlobStorageUrl(url), range, bins);

  if (isStatsLoading) {
    return <LoadingComponent />;
  }

  if (!stats) {
    return null;
  }

  return <Component {...(props as JSX.IntrinsicAttributes & T)} stats={stats} />;
};
