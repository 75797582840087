import { type ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { ScrollbarWrapper } from 'ui/atoms/scrollbarWrapper';
import { LoadingWave } from 'ui/atoms/loadingWave';

interface CreatorOptionsColumnProps {
  isLoading?: boolean;
  width?: number;
  header?: string;
  children?: ReactNode;
  disabled?: boolean;
}

const COLUMN_HEADER_HEIGHT = 40;

export const CreatorOptionsColumn = ({
  children,
  isLoading,
  width,
  header,
  disabled: columnDisabled,
}: CreatorOptionsColumnProps) => {
  return (
    <Wrapper width={width} $disabled={columnDisabled}>
      {isLoading && <LoadingWave variant="TRANSPARENT" />}
      {header && <Header>{header}</Header>}
      <ScrollbarWrapper offsetY={header ? COLUMN_HEADER_HEIGHT : 0}>{children}</ScrollbarWrapper>
    </Wrapper>
  );
};

CreatorOptionsColumn.displayName = 'CreatorOptionsColumn';

const Wrapper = styled.div<{ width?: number; $disabled?: boolean }>`
  flex-shrink: 0;
  position: relative;
  width: ${({ width }) => (width ? width : 380)}px;
  min-width: 180px;
  height: 100%;
  background-color: ${({ theme }) => theme.color.mineShaftABitLighter};
  color: ${({ theme }) => theme.color.white};
  border-right: 1px solid ${({ theme }) => theme.color.mineShaftDark};
  word-break: break-word;

  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
      color: ${({ theme }) => theme.color.midGray};
    `}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.1;
  color: inherit;
  padding: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.color.mineShaftDark};
  height: ${COLUMN_HEADER_HEIGHT}px;
`;
