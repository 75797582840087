import { useEffect } from 'react';
import styled from 'styled-components';

import { COORDINATES_PRECISION } from 'common/constants';
import { useAoiNumber } from 'common/navigation/hooks';
import { useExplorationLayerManagerStore } from 'views/exploration/explorationLayerManagerStore';
import { SectionLayout } from './SectionLayout';
import { SurfaceAreaSection } from './SurfaceAreaSection';
import { CommonCoverageSection } from './CommonCoverageSection';
import { PixelStatisticsSection } from './PixelStatisticsSection';
import { HistogramSection } from './HistogramSection';

export const StatisticsDrawerContent = () => {
  const areaId = useAoiNumber();
  const explorationLayersPointInfo = useExplorationLayerManagerStore.use.explorationLayersPointInfo();
  const setExplorationLayersPointInfo = useExplorationLayerManagerStore.use.setExplorationLayersPointInfo();

  const pixelCoordinates = explorationLayersPointInfo.length ? explorationLayersPointInfo[0].info.coordinates : null;

  useEffect(() => {
    return () => setExplorationLayersPointInfo([]);
  }, [areaId, setExplorationLayersPointInfo]);

  return (
    <Layout>
      <div>
        <Header>
          <Title>Area Information</Title>
          {/* <SelectWrapper>
            <Select
              name="statistics scope"
              options={[]}
              selectedOption={undefined}
              noOptionsLabel="data for total area"
            />
          </SelectWrapper>
          <InfoPoint name="area info description" tooltipContent="lorem ipsum dolor sit" /> */}
        </Header>
        <HistogramSection />
        <SurfaceAreaSection />
        <CommonCoverageSection />
      </div>
      <div>
        <Header>
          <Title>Pixel Information</Title>
          {pixelCoordinates &&
            `(Coordinates: ${pixelCoordinates[1].toFixed(COORDINATES_PRECISION)} ${pixelCoordinates[0].toFixed(
              COORDINATES_PRECISION,
            )})`}
        </Header>
        <SectionLayout title="curve information" description="Available soon">
          Available soon
          {/* <Select
            name="curve selection"
            options={[]}
            selectedOption={undefined}
            noOptionsLabel="Reference Curve Selection"
          />
          <CurvesPlaceholder /> */}
        </SectionLayout>
        <PixelStatisticsSection pixelStatistics={explorationLayersPointInfo} />
      </div>
    </Layout>
  );
};

const Layout = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 26px;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 32px;
`;

const Title = styled.h3`
  font-family: 'Barlow', sans-serif;
  text-transform: uppercase;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 36px;
`;

// const SelectWrapper = styled.div`
//   flex-grow: 0;
// `;

// const CurvesPlaceholder = styled.div`
//   width: 100%;
//   aspect-ratio: 16 / 10;
//   background-image: url('./curvesPlaceholder.png');
//   background-size: contain;
//   background-repeat: no-repeat;
//   background-position: center;
//   margin-top: 8px;
// `;
