import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { type ValueAndLabel } from 'common/types';
import { degreesToRadians, getLayerNameWithAoiId } from 'common/utils';
import { useAoiNumber } from 'common/navigation/hooks';
import { LoadingWave, Select } from 'ui';
import { useLayerManagerStore } from 'components';
import { useSurfaceMinerals } from 'views/exploration/useSurfaceMinerals';
import { WithCogStatistics } from 'services/titiler/WithCogStatistics';
import { HistogramGraph } from './HistogramGraph';
import { SectionLayout } from '../SectionLayout';

export const HistogramSection = () => {
  const { selectedMinerals, areMineralsLoading } = useSurfaceMinerals(1);

  const options = selectedMinerals.map(({ name, label }) => ({ label, value: name }));

  const [selectedMineral, setSelectedMineral] = useState<ValueAndLabel | undefined>(undefined);

  useEffect(() => {
    if (
      options.length &&
      (!selectedMineral || !options.map(({ value }) => value).includes(String(selectedMineral.value)))
    ) {
      setSelectedMineral(options[0]);
    }
  }, [options, selectedMineral]);

  const selectedMineralUrl = selectedMinerals.find(({ name }) => name === String(selectedMineral?.value))?.url;

  const rangeInDegrees = [0, 30];

  const rangeInRadians = rangeInDegrees.map(degreesToRadians);

  const range = rangeInRadians.map((value) => Number(Math.cos(value).toFixed(4))).sort((a, b) => a - b);

  const graphAllowance = 10;

  const layersTresholds = useLayerManagerStore.use.layersTresholdsValues();

  const areaId = useAoiNumber();

  const layerName = selectedMineral && getLayerNameWithAoiId(String(selectedMineral.value), areaId);

  const thresholdResponse = layerName ? layersTresholds[layerName] : undefined;

  const threshold = typeof thresholdResponse === 'number' ? thresholdResponse : thresholdResponse?.[0];

  return (
    <SectionLayout title="distribution of SAM results" description="lorem ipsum dolor sit">
      <SelectWrapper>
        <Select
          name="mineral selection"
          options={options}
          selectedOption={selectedMineral}
          onChange={setSelectedMineral}
          noOptionsLabel="kaolinite"
        />
      </SelectWrapper>
      <GraphWrapper>
        {areMineralsLoading && <LoadingWave height={400} />}
        {selectedMineralUrl ? (
          <WithCogStatistics
            Component={HistogramGraph}
            LoadingComponent={() => LoadingWave({ height: 400 })}
            url={selectedMineralUrl}
            props={{ range: rangeInDegrees, graphAllowance, threshold }}
            range={range}
            bins={30}
          />
        ) : (
          <div>no mineral selected</div>
        )}
      </GraphWrapper>
    </SectionLayout>
  );
};

const SelectWrapper = styled.div`
  width: 154px;
`;

const GraphWrapper = styled.div`
  margin: 10px auto 32px;
  width: 100%;
  height: 400px;
`;
