import styled from 'styled-components';

import { type LayerRange } from 'common/types';
import { Container } from 'ui';
import { ColorScaleBar } from 'ui/colorScale/ColorScaleBar';
import { StatisticsDot, StatisticsValue } from './styled';

interface Props {
  label: string;
  value: number;
  colormap: Record<number, string>;
  layerRange: LayerRange;
  grayed?: boolean;
}

export const PixelStatisticsTableRow = ({ label, value, colormap, layerRange: { min, max }, grayed }: Props) => {
  return (
    <StyledTableRow grayed={grayed}>
      <td>
        <Container gap={8}>
          <StatisticsDot>
            <ColorScaleBar colormap={colormap} />
          </StatisticsDot>
          <StatisticsValue grayed={grayed}>{label}</StatisticsValue>
        </Container>
      </td>
      <td>{value}</td>
      <td>{min}</td>
      <td>{max}</td>
    </StyledTableRow>
  );
};

const StyledTableRow = styled.tr<{ grayed?: boolean }>`
  > td:not(:first-child) {
    opacity: ${({ grayed }) => grayed && 0.7};
  }
`;
