import styled from 'styled-components';

interface ThresholdMarkerProps {
  value: number;
}

export const ThresholdMarker = ({ value }: ThresholdMarkerProps) => {
  return (
    <>
      <ThresholdLine value={value}>
        <Label>current threshold</Label>
      </ThresholdLine>
    </>
  );
};

const Label = styled.div`
  color: ${({ theme }) => theme.color.white};
  writing-mode: vertical-rl;
  transform: rotate(-180deg) translateX(16px);
  transform-origin: center;
  white-space: nowrap;
`;

export const ThresholdLine = styled.div<{ value: number }>`
  height: 100%;
  width: 1px;
  background: ${({ theme }) => theme.color.white};
  z-index: 25;
  position: absolute;
  left: ${({ value }) => `calc(${value}% - 1px)`};
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
`;
