import styled from 'styled-components';

export const Lines = () => {
  return Array(10)
    .fill(null)
    .map((el, i) => <Line offset={i * 10} key={i} />);
};

export const Line = styled.div<{ offset?: number }>`
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  border-bottom: 1px dashed ${({ theme }) => theme.color.silver};
  bottom: ${({ offset }) => offset || 10}%;
  z-index: -1;
`;
