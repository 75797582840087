/* eslint-disable @typescript-eslint/no-explicit-any */

import { type PointInfo, type CogBandStats, type SingleBandPointInfo } from './types';

export const areCogBandStatsValid = (stats: any): stats is CogBandStats => {
  return (
    stats && typeof stats.min === 'number' && typeof stats.max === 'number' && typeof stats.percentile_98 === 'number'
  );
};

export const isPointInfoValid = (info: any): info is SingleBandPointInfo => {
  return (
    info.coordinates &&
    Array.isArray(info.coordinates) &&
    info.coordinates.length === 2 &&
    info.coordinates.every(Number) &&
    info.band_names &&
    Array.isArray(info.band_names) &&
    info.band_names.length === 1 &&
    typeof info.band_names[0] === 'string' &&
    info.values &&
    Array.isArray(info.values) &&
    info.values.length === 1 &&
    typeof info.values[0] === 'number'
  );
};

export const isPointInfoArrayValid = <T>(data: PointInfo<T, any>[]): data is PointInfo<T, SingleBandPointInfo>[] => {
  return data && Array.isArray(data) && data.every((item) => isPointInfoValid(item.info));
};
