import styled from 'styled-components';

import { getPercentageValue } from 'common/utils';

interface XAxisValuesProps {
  range: number[];
}

export const XAxisValues = ({ range }: XAxisValuesProps) => {
  const step = (range[1] - range[0]) / 5;
  const labels = Array(6)
    .fill(null)
    .map((_, i) => range[0] + i * step);

  return (
    <List>
      {labels.map((value) => (
        <Item key={value} left={getPercentageValue(value, range)}>
          {value.toFixed()}
        </Item>
      ))}
    </List>
  );
};

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 95%;
  position: relative;
  display: flex;
  align-items: center;
`;

const Item = styled.li<{ left: number }>`
  left: ${({ left }) => `${left}%`};
  position: absolute;
`;
