import chroma from 'chroma-js';

import { getLayerNameWithAoiId } from 'common/utils';
import { useAoiNumber } from 'common/navigation/hooks';
import { useLayerManagerStore } from 'components';
import { type ExplorationLayer } from 'domain/exploration/types';
import { useSamLayersVisibility } from './minerals/useSurfaceLayersVisibility';
import { useSurfaceMinerals } from './useSurfaceMinerals';
import { useIndices, useIndicesLayersVisibility } from './indices';

export const useExplorationLayers = () => {
  const areaId = useAoiNumber();

  const { selectedMinerals: samMinerals } = useSurfaceMinerals(1);
  const { classesVisibility: samMineralsVisibility } = useSamLayersVisibility();

  const { indicesResults: indices } = useIndices();
  const { classesVisibility: indicesVisibility } = useIndicesLayersVisibility();

  const layersTresholds = useLayerManagerStore.use.layersTresholdsValues();

  const visibleSamMinerals = samMinerals.filter(({ name }) => !!samMineralsVisibility[name]);
  const visibleIndices = indices.filter(({ name }) => !!indicesVisibility[name]);

  const visibleSamMineralsLayers: ExplorationLayer[] = visibleSamMinerals.map(({ id, name, label, url, color }) => {
    const group = 'SAM';
    const hexColor = chroma(color).hex();
    const layerName = getLayerNameWithAoiId(name, areaId);

    return {
      uid: `${group}_${id}`,
      name,
      label,
      url,
      colormap: { [0]: hexColor, [1]: hexColor },
      threshold: layersTresholds[layerName],
      group,
    };
  });

  const visibleIndicesLayers: ExplorationLayer[] = visibleIndices.map(({ id, name, label_name, url, colormap }) => {
    const group = 'GSI';
    const layerName = getLayerNameWithAoiId(name, areaId);

    return {
      uid: `${group}_${id}`,
      name,
      label: label_name,
      url,
      colormap,
      threshold: layersTresholds[layerName],
      group,
    };
  });

  const visibleExplorationLayers = [...visibleSamMineralsLayers, ...visibleIndicesLayers];

  return { visibleExplorationLayers };
};
