export const TITILER_PREFIX = process.env.REACT_APP_TITILER_BASE_URL || '/tms';

export const TITILER_COG_API_URLS = {
  getImage: '/cog/preview.webp',
  getTif: '/cog/preview.tif',
  getCog: '/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x',
  getCogInfo: '/cog/info',
  getCogStatistics: '/cog/statistics',
  getPointInfo: '/cog/point',
  getCogPoint: '/cog/point/{lng},{lat}',
};
