import { radiansToDegrees } from 'common/utils';
import { type Segment } from './types';

export const getLimitedSegments = (segments: Segment[], range: number[]) => {
  return segments.filter((segment) => segment.start >= range[0] && segment.end <= range[1]);
};

export const mapXYValuesToSegments = (xValues: number[], yValues: number[]) => {
  return (
    yValues?.map((value, index) => ({ value, start: Math.acos(xValues[index + 1]), end: Math.acos(xValues[index]) })) ||
    []
  );
};

export const getFilteredSegments = (segments: Segment[], range: number[]) => {
  return segments
    .map((segment) => ({
      start: radiansToDegrees(segment.start),
      end: radiansToDegrees(segment.end),
      value: segment.value,
    }))
    .filter((segment) => segment.start >= range[0] && segment.end <= range[1]);
};
