/* eslint-disable @typescript-eslint/no-explicit-any */
import { type AxiosResponse } from 'axios';
import { type UseQueryResult } from '@tanstack/react-query';
import { type Point } from 'geojson';

import { type Money, type ChatResponse, type ExtendedIndicator } from 'common/types';
import { type CogResult, type UrlResult } from 'common/types/api';
import {
  type DefaultView,
  type Bounds,
  type DataPoint,
  type BoundsArray,
  type LeafletBounds,
  type GeoJsonResultDto,
  type MapPoint,
} from 'common/types/mapData';

export const isArrayOfType = (array: any, type: 'string' | 'number' | 'boolean' | 'object') =>
  Array.isArray(array) && typeof array[0] === type;

export const isDataPoint = (point: any): point is DataPoint => {
  return typeof point?.id === 'number';
};

export const isSuccessfulQueryUrl = (response: any): response is AxiosResponse<UrlResult> => {
  return typeof response?.data?.url === 'string';
};

export const isValidBounds = (bounds: any): bounds is Bounds => {
  return bounds && Array.isArray(bounds) && bounds.length === 4 && bounds.every((bound) => typeof bound === 'number');
};

export const isCogResultValid = (result: any): result is AxiosResponse<CogResult> => {
  return result && typeof result?.status === 'string' && typeof result?.data?.url === 'string';
};

export const isCogResultQueriesResponseValid = (
  results: any,
): results is UseQueryResult<AxiosResponse<CogResult>>[] => {
  return (
    results &&
    isArrayOfType(results, 'object') &&
    results.every((result: AxiosResponse<CogResult>) => isCogResultValid(result))
  );
};

export const isExtendedIndicatorValid = (indicator: any): indicator is ExtendedIndicator => {
  return (
    indicator &&
    typeof indicator.id === 'number' &&
    typeof indicator.indicator_name === 'string' &&
    typeof indicator.min_value === 'number' &&
    typeof indicator.max_value === 'number' &&
    typeof indicator.colormap === 'object' &&
    typeof indicator.unit === 'string' &&
    Object.values(indicator.colormap).every(String) &&
    Array.isArray(indicator.labels) &&
    indicator.labels.every(String) &&
    typeof indicator.precision === 'number' &&
    typeof indicator.description === 'string'
  );
};

export const areExtendedIndicatorsValid = (indicators: any): indicators is ExtendedIndicator[] => {
  return Array.isArray(indicators) && indicators.every(isExtendedIndicatorValid);
};

export const isChatResponseValid = (response: any): response is ChatResponse => {
  return (
    typeof response?.id === 'string' &&
    typeof response?.object === 'string' &&
    typeof response?.model === 'string' &&
    typeof response?.created === 'number' &&
    Array.isArray(response?.choices) &&
    typeof response.choices[0].message.content === 'string'
  );
};

export const isMoneyValid = (money: any): money is Money => {
  return typeof money?.amount === 'number' && typeof money?.currency === 'string' && typeof money?.symbol === 'string';
};

export const isDefaultPointView = (view: any): view is DefaultView => {
  return view && typeof view.lat === 'number' && typeof view.lon === 'number' && typeof view.zoom === 'number';
};

const isPointArray = (arr: any): arr is [number, number] => {
  return Array.isArray(arr) && arr.length === 2 && typeof arr[0] === 'number' && typeof arr[1] === 'number';
};

export const isBoundsArray = (arr: any): arr is BoundsArray => {
  return Array.isArray(arr) && arr.length === 2 && isPointArray(arr[0]) && isPointArray(arr[1]);
};

export const isLeafletBoundsValid = (bounds: any): bounds is LeafletBounds => {
  return (
    bounds &&
    bounds._northEast &&
    typeof bounds._northEast.lat === 'number' &&
    typeof bounds._northEast.lng === 'number' &&
    bounds._southWest &&
    typeof bounds._southWest.lat === 'number' &&
    typeof bounds._southWest.lng === 'number'
  );
};

export const isGeoJsonPointValid = (point: any): point is Point => {
  return (
    point &&
    point.type === 'Point' &&
    Array.isArray(point.coordinates) &&
    point.coordinates.length === 2 &&
    point.coordinates.every((coordinate: any) => typeof coordinate === 'number')
  );
};

export const isGeoJsonResultValid = (shape: any): shape is GeoJsonResultDto => {
  return (
    shape &&
    typeof shape.id === 'number' &&
    typeof shape.aoi_name === 'string' &&
    typeof shape.label_name === 'string' &&
    typeof shape.name === 'string' &&
    typeof shape.description === 'string' &&
    typeof shape.shape_url === 'string'
  );
};

export const isGeoJsonResultsArrayValid = (shapes: any): shapes is GeoJsonResultDto[] => {
  return Array.isArray(shapes) && shapes.some((shape) => isGeoJsonResultValid(shape));
};

export const isMapPointValid = (point: any): point is MapPoint => {
  return point && typeof point.lat === 'number' && typeof point.lng === 'number';
};
