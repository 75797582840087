import { useQueries, useQuery } from '@tanstack/react-query';
import { titilerApiService } from './titilerApiService';
import { areCogBandStatsValid } from './typeguards';
import { convertCogBandStatsToRange } from './utils';

export const titilerInfoService = {
  // TODO: Deprecated method, migrate gradually to useGetInfoGeneric
  useGetInfo: (url: string) => {
    const { data: info, isLoading } = useQuery(['georaster_info', url], () => titilerApiService.getInfo(url));

    return { info, isInfoLoading: isLoading };
  },

  useGetInfoGeneric: (url: string) => {
    const { data: info, isLoading } = useQuery(['georaster_info', url], () => titilerApiService.getInfoGeneric(url));

    return { info, isInfoLoading: isLoading };
  },

  useGetArrayInfo: (sets: { url: string; id: number }[]) => {
    const results = useQueries({
      queries: sets.map(({ url, id }) => ({
        queryKey: ['georaster_info', id],
        queryFn: () => titilerApiService.getRichInfo(url, id),
      })),
    });

    return results;
  },

  // TODO: Deprecated method, migrate gradually to useGetStatsGeneric
  useGetStats: (url: string) => {
    const { data: stats, isLoading } = useQuery(['georaster_stats', url], () => titilerApiService.getStats(url));

    return { stats, isStatsLoading: isLoading };
  },

  useGetStatsGeneric: (url: string, range?: number[], bins?: number) => {
    const { data: stats, isLoading } = useQuery(['georaster_stats', url], () =>
      titilerApiService.getStatsGeneric(url, range, bins),
    );

    return { stats, isStatsLoading: isLoading };
  },

  useGetCogBandRange: (url: string, enabled: boolean, precision = 2, band = 1) => {
    const { data, isInitialLoading } = useQuery(
      ['georaster_stats', url],
      () => titilerApiService.getCogBandStats(url, band),
      {
        enabled,
      },
    );

    const stats = data && Object.values(data);
    const bandStats = stats && Array.isArray(stats) && stats.length && stats[0];
    const validBandStats = bandStats && areCogBandStatsValid(bandStats) ? bandStats : null;
    const cogBandRange = validBandStats ? convertCogBandStatsToRange(validBandStats, precision) : null;

    return { cogBandRange, isLoading: isInitialLoading };
  },

  useGetArrayStats: (sets: { url: string; name: string }[]) => {
    const results = useQueries({
      queries: sets.map(({ url, name }) => ({
        queryKey: ['georaster_stats', name],
        queryFn: () => titilerApiService.getRichStats(url, name),
      })),
    });

    return results;
  },

  useGetArrayStatsGeneric: <T>(sets: { url: string; props: T }[]) => {
    const results = useQueries({
      queries: sets.map(({ url, props }) => ({
        queryKey: ['georaster_stats', props],
        queryFn: () => titilerApiService.getStatsWithPropsGeneric(url, props),
      })),
    });

    return results;
  },
};
